import { useState, type MutableRefObject } from 'react'

import { AtlasBodySubtitle, TextBoxMolecule } from '@justomx/atlas-components'
import { ConfirmationDialogMolecule } from 'components/molecules/ConfirmationDialog.molecule'
import { ContainerAtom } from 'components/atoms/Container.atom'

interface OptionsDialogProps {
  dialogRef: MutableRefObject<HTMLDialogElement | null>
  title: string
  textboxLabel: string
  confirmLabel: string
  cancelLabel: string
  confirmAction: (value: string) => void
}

export const InputDialogOrganism = (props: OptionsDialogProps) => {
  const {
    dialogRef,
    title,
    textboxLabel,
    confirmLabel,
    cancelLabel,
    confirmAction
  } = props
  const [value, setValue] = useState('')

  const _onConfirm = () => {
    confirmAction(value)
    setValue('')
  }

  return (
    <ConfirmationDialogMolecule
      dialogRef={dialogRef}
      confirmText={confirmLabel}
      onConfirm={() => {
        _onConfirm()
      }}
      onCancel={() => {
        setValue('')
      }}
      cancelText={cancelLabel}
    >
      <AtlasBodySubtitle>{title}</AtlasBodySubtitle>

      <ContainerAtom $padding="24px 0 0 0">
        <TextBoxMolecule
          label={textboxLabel}
          onChange={setValue}
          value={value}
        />
      </ContainerAtom>
    </ConfirmationDialogMolecule>
  )
}
