import { toastError } from 'utils/toast'

export const handleError = (error: any): void => {
  let stringError = ''
  if (error) {
    try {
      stringError = JSON.stringify(error)
    } catch (e) {
      stringError =
        'An unexpected error occurred, there are no error details to display'
    }
  } else {
    stringError = 'There are no error details to display'
  }
  toastError(stringError)
}
