import type { TFunction } from 'i18next'
import * as Yup from 'yup'
import { TEMP_RANGES } from 'utils/constants'

export const getValidationSchema = (
  t: TFunction<'global', undefined>,
  zone: string,
  validateRanges: boolean
) => {
  return Yup.object({
    temperature: Yup.number().concat(
      validateRanges
        ? Yup.number()
          .required(t('validations.required'))
          .min(TEMP_RANGES[zone].min, t('validations.temp-range'))
          .max(TEMP_RANGES[zone].max, t('validations.temp-range'))
        : Yup.number().notRequired()
    )
  })
}

export const mapFormValues = (zone: string, temperature?: number) => {
  const range = TEMP_RANGES[zone]
  if (temperature === null || temperature === undefined) {
    return { temperature: '', outOfRangeApproved: false }
  }

  const outOfRangeApproved = !(
    temperature >= range.min && temperature <= range.max
  )
  return { temperature: temperature.toString(), outOfRangeApproved }
}
