import { useLoaderData } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { useEffect } from 'react'

import { UserManagment } from '@justomx/webview-commons'
import type { OrderSummary } from 'types/receipt/model'
import { containerTypeAtom } from './Receipt.state'
import { useGoToOrderDetail } from './order-detail/OrderDetail.navigator'
import { useGoToReceiptType } from './receipt-type/ReceiptType.navigator'
import { useGoToTemperature } from './temperature/Temperature.navigator'
import { RECEIPT_TYPES } from 'utils/constants'
import { getLoginUrl } from 'utils/environment'

export const useReceiptData = () => {
  const data = useLoaderData()
  const orderDetail = useGoToOrderDetail()
  const receiptType = useGoToReceiptType()
  const temperature = useGoToTemperature()
  const setContainerType = useSetAtom(containerTypeAtom)

  useEffect(() => {
    setContainerType('')
  }, [])

  const onSelectOrder = (order: OrderSummary) => {
    if (order.frozenOrRefrigerated) {
      temperature.go(order.receiptId, RECEIPT_TYPES[order.documentType])
    } else {
      orderDetail.go(
        RECEIPT_TYPES[order.documentType],
        order.receiptId,
        order.orderId
      )
    }
  }

  const onSelectSync = () => {
    receiptType.go()
  }

  const logout = () => {
    UserManagment.logout()
    window.location.href = getLoginUrl()
  }

  return {
    data,
    actions: { onSelectOrder, onSelectSync, logout }
  }
}
