import { useEffect } from 'react'
import { Outlet, useMatches, useNavigation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import LoaderAtom from 'components/atoms/Loader.atom'

export const MainLayout = () => {
  const { t } = useTranslation('global')
  const navigation = useNavigation()
  const matches = useMatches()
  const { id } = matches[matches.length - 1]
  const title = id

  useEffect(() => {
    if (title) {
      document.title = title
    }
  }, [title])

  if (navigation.state === 'loading') {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return <Outlet />
}
