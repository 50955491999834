import { receiptAxiosInstance } from 'client/receipt-api.axios.instance'
import type {
  UpsertProductLotsData,
  OrderDetail,
  SyncOrderResponse,
  GetProductLotsResponse,
  UpsertRejectionData,
  RejectionSummary,
  UpdateMissingsData,
  ReceiptProductProcessed,
  RejectionData,
  ContainersStatus,
  OrderSummary
} from 'types/receipt/model'

export const syncOrder = async (
  documentType: string,
  sapOrderCode: string
): Promise<SyncOrderResponse> => {
  const axios = receiptAxiosInstance()
  return await axios
    .post('api/documents/sync', {
      id: sapOrderCode,
      documentType
    })
    .then((e) => e.data)
}

export const getPurchaseOrders = async (): Promise<OrderSummary[]> => {
  const axios = receiptAxiosInstance()
  return await axios.get('api/orders/purchase/summary').then((e) => e.data)
}

export const getOrderDetail = async (
  receiptId: string
): Promise<OrderDetail> => {
  const axios = receiptAxiosInstance()
  return await axios.get(`api/orders/purchase/${receiptId}`).then((e) => e.data)
}

export const getProductLots = async (
  receiptId: string,
  productId: string
): Promise<GetProductLotsResponse> => {
  const axios = receiptAxiosInstance()
  return await axios
    .get(`api/orders/purchase/product-lots/${receiptId}/${productId}`)
    .then((e) => e.data)
}

export const upsertProductLots = async (
  data: UpsertProductLotsData
): Promise<any> => {
  const axios = receiptAxiosInstance()
  return await axios
    .patch('api/orders/purchase/product-lots', data)
    .then((e) => e.data)
}

export const upsertRejection = async (
  data: UpsertRejectionData
): Promise<any> => {
  const axios = receiptAxiosInstance()
  return await axios
    .patch('api/orders/purchase/rejections', data)
    .then((e) => e.data)
}

export const uploadFile = async (file: File): Promise<string> => {
  const axios = receiptAxiosInstance()
  const formData = new FormData()
  formData.append('files', file)
  return await axios
    .post('api/s3', formData)
    .then((response) => response.data?.[0])
}

export const signFileUrls = async (keys: string[]): Promise<string[]> => {
  const axios = receiptAxiosInstance()
  const stringKeys = JSON.stringify(keys)
  return await axios
    .get(`api/s3?fileKeys=${stringKeys}`)
    .then((response) => response.data)
}

export const getRejectionSummary = async (
  receiptId: string,
  productId: string,
  rejectionId?: string
): Promise<RejectionSummary> => {
  const axios = receiptAxiosInstance()
  let endpoint = `api/orders/purchase/rejections/${receiptId}/${productId}`
  if (rejectionId) {
    endpoint += `?rejectionId=${rejectionId}`
  }

  return await axios.get(endpoint).then((e) => e.data)
}

export const updateMissings = async (
  data: UpdateMissingsData
): Promise<ReceiptProductProcessed> => {
  const axios = receiptAxiosInstance()
  return await axios
    .put('api/orders/purchase/missings', data)
    .then((e) => e.data)
}

export const getProductProcessed = async (
  receiptId: string,
  productId: string
): Promise<ReceiptProductProcessed> => {
  const axios = receiptAxiosInstance()
  return await axios
    .get(`api/orders/purchase/product-processed/${receiptId}/${productId}`)
    .then((e) => e.data)
}

export const deleteRejection = async (
  receiptId: string,
  productId: string,
  rejectionId: string
): Promise<RejectionData[]> => {
  const axios = receiptAxiosInstance()
  return await axios
    .delete(
      `api/orders/purchase/rejections/${receiptId}/${productId}/${rejectionId}`
    )
    .then((e) => e.data)
}

export const getContainers = async (
  receiptId: string
): Promise<ContainersStatus> => {
  const axios = receiptAxiosInstance()
  return await axios
    .get(`api/orders/purchase/containers/${receiptId}`)
    .then((e) => e.data)
}

export const moveContainer = async (
  receiptId: string,
  containerName: string,
  locationName: string
): Promise<any> => {
  const axios = receiptAxiosInstance()
  return await axios
    .post('api/orders/purchase/containers/move', {
      receiptId,
      containerName,
      locationName
    })
    .then((e) => e.data)
}

export const finishReceipt = async (
  receiptId: string,
  type = 'purchase'
): Promise<any> => {
  const axios = receiptAxiosInstance()
  return await axios
    .post(`api/orders/${type}/confirm/${receiptId}`)
    .then((e) => e.data)
}

export const updateTemperature = async (
  receiptId: string,
  temperature: number
): Promise<any> => {
  const axios = receiptAxiosInstance()
  return await axios
    .patch('api/orders/metadata/temperature', { receiptId, temperature })
    .then((e) => e.data)
}

export const validateContainer = async (
  containerName: string
): Promise<any> => {
  const axios = receiptAxiosInstance()
  return await axios
    .get(`api/orders/purchase/containers/validate/${containerName}`)
    .then((e) => e.data)
}
