export type Warehouse = {
  wmsId: number;
  wmsName: WarehouseName;
  wmsWhCode: WarehouseSapCode;
  wmsWarehouseType: string;
  coreName: string;
  coreId: number;
  country: WarehouseCountry;
  coreDescription: string;
};

export type WarehouseCountry = 'BR' | 'MX' | 'PE';
export type WarehouseName = 'CO' | 'ZA' | 'SM' | 'JU' | 'AT' | 'SD' | 'NM' | 'AY' | 'AB' | 'AGU';
export type WarehouseSapCode = 'DF0007G' | 'JA0001G' | 'NL0001G' | 'QT0001G' | 'MX0002G' | 'MX0001G' | 'DF0004G' | '2G' | '1G' | '01';
export const isWarehouseName = (str: string): str is WarehouseName => {
  return Boolean(Warehouses.find(e => e.wmsName === str))
};

export const isWarehouseSapCode = (str: string): str is WarehouseSapCode => {
  return Boolean(Warehouses.find(e => e.wmsWhCode === str));
};


export const Warehouses: Warehouse[] = [
  {
    wmsId: 144,
    wmsName: 'CO',
    wmsWhCode: 'DF0007G',
    wmsWarehouseType: 'bodega',
    country: 'MX',
    coreName: 'MXJFCCOYOACAN',
    coreId: 14,
    coreDescription : 'Coyoacan'
  },
  {
    wmsId: 132,
    wmsName: 'ZA',
    wmsWhCode: 'JA0001G',
    wmsWarehouseType: 'bodega',
    country: 'MX',
    coreName: 'MXJFCZAPOPAN',
    coreId: 7,
    coreDescription : 'Zapopan'
  },
  {
    wmsId: 130,
    wmsName: 'SM',
    wmsWhCode: 'NL0001G',
    wmsWarehouseType: 'bodega',
    country: 'MX',
    coreId: 11,
    coreName: 'MXJFCMONTERREY',
    coreDescription :'Monterrey'
  },
  {
    wmsId: 129,
    wmsName: 'JU',
    wmsWhCode: 'QT0001G',
    wmsWarehouseType: 'bodega',
    country: 'MX',
    coreId: 4,
    coreName: 'MXJFCJURICA',
    coreDescription : 'Queretaro'
  },
  {
    wmsId: 126,
    wmsName: 'AY',
    wmsWhCode: 'DF0004G',
    wmsWarehouseType: 'bodega',
    country: 'MX',
    coreId: 6,
    coreName: 'MXJFCAYUNTAMIENTO',
    coreDescription :'Ayuntamiento'
  },
  {
    wmsId: 116,
    wmsName: 'AT',
    wmsWhCode: 'MX0002G',
    wmsWarehouseType: 'bodega',
    country: 'MX',
    coreId: 9,
    coreName: 'MXJFCATIZAPAN',
    coreDescription: 'Atizapan'
  },
  {
    wmsId: 104,
    wmsName: 'AB',
    wmsWhCode: 'MX0001G',
    wmsWarehouseType: 'cedis',
    country: 'MX',
    coreId: 5,
    coreName: 'MXJFCALCEBLANCO',
    coreDescription: 'Alce Blanco'
  },
  {
    wmsId: 126,
    wmsName: 'NM',
    wmsWhCode: '2G',
    wmsWarehouseType: 'bodega',
    country: 'BR',
    coreId: 15,
    coreName: 'BRJFCSAOPAULO02',
    coreDescription: 'Novo Mundo'
  },
  {
    wmsId: 111,
    wmsName: 'SD',
    wmsWhCode: '1G',
    wmsWarehouseType: 'bodega',
    country: 'BR',
    coreId: 10,
    coreName: 'BRJFCSAOPAULO01',
    coreDescription: 'São Paulo'
  },
  {
    wmsId: 325,
    wmsName: 'AGU',
    wmsWhCode: '01',
    wmsWarehouseType: 'bodega',
    coreId: 16,
    country: 'PE',
    coreName: 'PEJFCSAOLIMA01',
    coreDescription: 'El Agustino'
  }
];

export const getWarehousesByCountry = (country: WarehouseCountry): Warehouse[] => {
  return Warehouses.filter((warehouse) => warehouse.country === country);
};

export const getWarehouseByName = (str: string): Warehouse | undefined => {
  return Warehouses.find((warehouse) => warehouse.wmsName === str);
};

export const getWarehouseByDescription = (str: string): Warehouse | undefined => {
  return Warehouses.find((warehouse) => warehouse.coreDescription === str);
};

export const getWarehouseBySapCode = (str: string): Warehouse | undefined => {
  return Warehouses.find((warehouse) => warehouse.wmsWhCode === str);
};

export const getWarehouseByCoreId = (id: number): Warehouse | undefined => {
  return Warehouses.find((warehouse) => warehouse.coreId === id);
};

export const getWarehouseByCoreName = (name: string): Warehouse | undefined => {
  return Warehouses.find((warehouse) => warehouse.coreName === name);
};

export const getWarehouseByWmsId = (id: number): Warehouse | undefined => {
  return Warehouses.find((warehouse) => warehouse.wmsId === id);
};

export const getWarehouseByWmsName = (name: string): Warehouse | undefined => {
  return Warehouses.find((warehouse) => warehouse.wmsName === name);
};

export const getWarehouseByWmsWhCode = (code: string): Warehouse | undefined => {
  return Warehouses.find((warehouse) => warehouse.wmsWhCode === code);
};

export const getWarehouseByWmsWarehouseType = (type: string): Warehouse | undefined => {
  return Warehouses.find((warehouse) => warehouse.wmsWarehouseType === type);
};

export const getWarehouseFromLocation = (location: string): Warehouse | undefined => {
  const warehouseWmsName = location.split('-')[0].toUpperCase();
  return getWarehouseByWmsName(warehouseWmsName);
}