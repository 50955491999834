import { useLoaderData } from 'react-router-dom'

import { UserManagment } from '@justomx/webview-commons'
import { useGoToPickingList } from './PickingList/PickingList.navigator'
import type { OutputOrderData } from 'types/inventory-transfer/model'
import { getLoginUrl } from 'utils/environment'

export const useInventoryTransfer = () => {
  const pickingList = useGoToPickingList()
  const loaderData = useLoaderData() as any

  const onSelectOrder = (orderData: OutputOrderData) => {
    pickingList.go(orderData.order, orderData._id)
  }

  const logout = () => {
    UserManagment.logout()
    window.location.href = getLoginUrl()
  }

  return { actions: { onSelectOrder, logout }, loaderData }
}
