import { createBrowserRouter } from 'react-router-dom'

import { MainLayout } from './Main.layout'
import { receiptRoutes } from './receipt/routes'
import { inventoryTransferRoutes } from './inventory-transfer/routes'
import ErrorPage from './error/Error.page'

export const router = createBrowserRouter([
  {
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [...receiptRoutes, ...inventoryTransferRoutes]
  }
])
