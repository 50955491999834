import { useSetAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'

import { containerTypeAtom } from '../Receipt.state'
import { useGoToScanContainer } from '../scan-container/ScanContainer.navigator'
import { useRequiredParams } from 'hooks/params.hook'

export const useContainerType = () => {
  const navigate = useNavigate()
  const setContainerType = useSetAtom(containerTypeAtom)
  const scanContainer = useGoToScanContainer()
  const { receiptId, orderId } = useRequiredParams('receiptId', 'orderId')

  const goBack = () => {
    navigate(-1)
  }

  const onSelectType = (type: string) => {
    setContainerType(type)
    scanContainer.go(receiptId, orderId, 'lots')
  }

  return { actions: { goBack, onSelectType } }
}
