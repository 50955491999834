import { useNavigate, useRouteError } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ErrorPage as AtlasErrorPage } from '@justomx/atlas-components'

interface CouldBeError {
  statusText?: string
  message?: string
}

const couldBeError = (e: unknown): e is CouldBeError => {
  return (
    typeof e === 'object' && e !== null && ('statusText' in e || 'message' in e)
  )
}

const ErrorPage = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const error = useRouteError()

  return (
    <AtlasErrorPage
      title={t('validations.generic-error')}
      reason={
        couldBeError(error)
          ? error.statusText || error.message || ''
          : JSON.stringify(error)
      }
      action={() => {
        navigate(-1)
      }}
    />
  )
}

export default ErrorPage
