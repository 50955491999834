import {
  type CustomAxiosRequestConfig,
  receiptAxiosInstance
} from 'client/receipt-api.axios.instance'
import type {
  CreatePickData,
  OutputOrderData
} from 'types/inventory-transfer/model'

export const getOutputOrders = async (): Promise<OutputOrderData[]> => {
  const axios = receiptAxiosInstance()
  return await axios
    .get('api/output-order/list?status=pending')
    .then((e) => e.data)
}

export const getOrder = async (orderId: string): Promise<OutputOrderData> => {
  const axios = receiptAxiosInstance()
  return await axios.get(`api/output-order/${orderId}`).then((e) => e.data)
}

export const createPick = async (orderId: string, pick: CreatePickData) => {
  const axios = receiptAxiosInstance()
  const options: CustomAxiosRequestConfig = { skipErrorAlert: true }
  return await axios
    .post(`api/output-order/${orderId}/pick`, pick, options)
    .then((e) => e.data)
}

export const deletePick = async (
  order: string,
  barcode: string,
  pickId: string
) => {
  const axios = receiptAxiosInstance()
  return await axios
    .delete(`api/output-order/${order}/barcode/${barcode}/pick/${pickId}`)
    .then((e) => e.data)
}

export const updateOrderStatus = async (orderId: string, status: string) => {
  const axios = receiptAxiosInstance()
  return await axios
    .patch(`api/output-order/${orderId}/status/${status}`)
    .then((e) => e.data)
}

export const updateMissing = async (order: string, barcode: string) => {
  const axios = receiptAxiosInstance()
  return await axios
    .put('api/output-order/missings', { order, barcode })
    .then((e) => e.data)
}
