import type {
  ProductDetail,
  ReceiptProductProcessed,
  ReceiptProductSummary,
  UpdateMissingsData
} from 'types/receipt/model'
import { MEASURE_UNITS } from 'utils/constants'

export const getTotal = (
  productProcessed: ReceiptProductProcessed,
  product: ProductDetail
) => {
  const { measureUnit, quantity, totalUnits } = product
  const {
    received,
    rejected,
    reportedMissing = 0,
    reportedMissingBoxes = 0
  } = productProcessed
  let total = 0
  let expectedQuantity = totalUnits

  if (measureUnit === MEASURE_UNITS.box) {
    expectedQuantity = quantity
    received?.lots.forEach((lot) => (total += lot.boxes))
    rejected?.forEach((rej) => (total += rej.boxes || 0))
    total += reportedMissingBoxes
  } else {
    received?.lots.forEach((lot) => (total += lot.units))
    rejected?.forEach((rej) => (total += rej.quantity))
    total += reportedMissing
  }

  return { total, expectedQuantity }
}

export const mapToSummaryData = (data: ReceiptProductProcessed) => {
  const { received, rejected, reportedMissing } = data
  const summary: Record<string, ReceiptProductSummary[]> = {}

  if (received?.lots.length) {
    summary.received = received.lots.map((lot) => ({
      status: 'received',
      lot: lot.lotCode,
      amount: lot.units,
      boxes: lot.boxes,
      expirationDate: lot.expirationDate,
      container: lot.containerName
    }))
  }
  if (rejected?.length) {
    summary.rejected = rejected?.map((rej) => ({
      id: rej._id,
      status: 'rejected',
      lot: rej.lot,
      amount: rej.quantity,
      rejectReasons: rej.rejectedReasons,
      evidences: rej.photos
    }))
  }
  if (reportedMissing) {
    summary.missing = [
      {
        status: 'missing',
        amount: reportedMissing
      }
    ]
  }
  return summary
}

export const deleteLotFromData = (
  receiptId: string,
  data: ReceiptProductProcessed,
  index: number
) => {
  return {
    receiptId,
    product: {
      id: data.sapProductId,
      barcode: data.ean,
      expectedQuantity: data.expectedQuantity
    },
    lots: data.received.lots.filter((_, idx) => idx !== index)
  }
}

export const getUpdateMissingData = (
  receiptId: string,
  data: ReceiptProductProcessed
): UpdateMissingsData => {
  return {
    receiptId,
    product: {
      id: data.sapProductId,
      barcode: data.ean,
      expectedQuantity: data.expectedQuantity
    },
    isFullMissing: false,
    reportedMissing: 0
  }
}
