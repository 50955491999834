import { Form, FormikProvider } from 'formik'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  BottomGroupMolecule,
  CardComponent,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  SpacerAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { CheckboxMolecule } from 'components/molecules/Checkbox.molecule'
import { FlexColumnAtom } from 'components/atoms/FlexColumn.atom'
import { useRejectionData } from './Rejection.hooks'
import { FormikTextFieldOrganism } from 'components/organisms/FormikTextField.organism'
import { maxPhotos, getRejectReasons } from './Rejection.helpers'
import LoaderAtom from 'components/atoms/Loader.atom'
import { S3ImagesPreviewMolecule } from 'components/molecules/S3ImagesPreview.molecule'
import { UploadFileMolecule } from 'components/molecules/UploadFile.molecule'
import { MEASURE_UNITS } from 'utils/constants'

const EvidenceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RejectionPage = () => {
  const { t } = useTranslation('global')
  const { formConfig, fileKeys, loading, product, actions } = useRejectionData()

  if (!product) {
    return
  }

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}

      <ToolbarMolecule
        title={t('receipt-rejection.title')}
        actionIcon={Icons.ArrowBack}
        onAction={actions.goBack}
      />

      <PageContentAtom $gap={32}>
        <EvidenceContainer>
          <FlexColumnAtom>
            <AtlasBodySubtitle>
              {t('receipt-rejection.evidence')}
            </AtlasBodySubtitle>
            <AtlasBodyMain>
              {t('receipt-rejection.max-evidences')}
            </AtlasBodyMain>
          </FlexColumnAtom>

          <UploadFileMolecule
            disabled={fileKeys.length === maxPhotos}
            onNewPhoto={actions.addPhoto}
          />
        </EvidenceContainer>

        {!!fileKeys.length && (
          <CardComponent $variant="highlight">
            <S3ImagesPreviewMolecule
              s3Keys={fileKeys}
              removePhoto={actions.removePhoto}
            />
          </CardComponent>
        )}

        <AtlasBodySubtitle>
          {t('receipt-rejection.form-title')}
        </AtlasBodySubtitle>

        <FormikProvider value={formConfig}>
          <Form onSubmit={formConfig.handleSubmit}>
            <FlexColumnAtom $gap={32}>
              {product.measureUnit === MEASURE_UNITS.box && (
                <FormikTextFieldOrganism
                  type="number"
                  name="boxes"
                  label={t('receipt-rejection.boxes')}
                  formConfig={formConfig}
                />
              )}

              <FormikTextFieldOrganism
                type="number"
                name="amount"
                label={t(
                  product.measureUnit === 'KG'
                    ? 'receipt-rejection.units-kg'
                    : 'receipt-rejection.units'
                )}
                formConfig={formConfig}
              />

              <FormikTextFieldOrganism
                type="text"
                name="lot"
                label={t('receipt-rejection.lot')}
                formConfig={formConfig}
              />

              <FlexColumnAtom>
                {getRejectReasons(product.zone).map((reason) => (
                  <CheckboxMolecule
                    key={reason}
                    name={'reason'}
                    value={reason}
                    checked={formConfig.values.reason.includes(reason)}
                    label={t(`receipt-rejection.reasons.${reason}`)}
                    onChange={formConfig.handleChange}
                  />
                ))}
              </FlexColumnAtom>
            </FlexColumnAtom>

            <SpacerAtom height={100} />
            <BottomGroupMolecule>
              <PrimaryButtonAtom type="submit" disabled={!formConfig.isValid}>
                {t('receipt-rejection.register')}
              </PrimaryButtonAtom>
            </BottomGroupMolecule>
          </Form>
        </FormikProvider>
      </PageContentAtom>
    </>
  )
}

export default RejectionPage
