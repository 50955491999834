import { getWarehouseByWmsName } from '@justomx/warehouse-commons'
import { type LoggedUser, UserManagment } from '@justomx/webview-commons'
import {
  getCurrentCountry,
  getCurrentWarehouse
} from 'client/utilities.client-helper'

export const useSessionData = () => {
  const country = getCurrentCountry()
  const warehouseCode = getCurrentWarehouse()
  const warehouse = getWarehouseByWmsName(warehouseCode)
  let user: LoggedUser | undefined

  try {
    user = UserManagment.getCurrentUser()
  } catch {}

  return { user, warehouse, country }
}
