import type { ContainerToMove } from 'types/receipt/model'

export const groupByStatus = (
  data: ContainerToMove[]
): Record<string, ContainerToMove[]> => {
  return data.reduce((acc, item) => {
    const { containerStatus } = item
    if (!acc[containerStatus]) {
      acc[containerStatus] = []
    }
    acc[containerStatus].push(item)
    return acc
  }, {})
}

export const tabs = ['toMove', 'moved']
export const defaultActiveTab = 'toMove'
