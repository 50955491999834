import { getRejectionSummary } from 'client/receipt/receipt.client'
import type { RejectionSummary } from 'types/receipt/model'

export const isTotalsData = (data: unknown): data is RejectionSummary => {
  return (
    !!data &&
    typeof data === 'object' &&
    'totalReceived' in data &&
    'totalRejected' in data &&
    'totalMissing' in data &&
    'totalReceivedBoxes' in data &&
    'totalRejectedBoxes' in data &&
    'totalMissingBoxes' in data
  )
}

export const partialMissingLoader = async ({ params }) => {
  return await getRejectionSummary(`${params.receiptId}`, `${params.productId}`)
}
