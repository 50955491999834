import { getOrder } from 'client/inventory-transfer/inventory-transfer.client'
import type { ProductLocation } from 'types/commons/model'
import type { OutputOrderData } from 'types/inventory-transfer/model'

export const isOutputOrder = (data: unknown): data is OutputOrderData => {
  return (
    !!data &&
    typeof data === 'object' &&
    'order' in data &&
    'status' in data &&
    'products' in data
  )
}

const groupLocations = (locations: ProductLocation[]): ProductLocation[] => {
  const grouped = {}
  locations.forEach((location) => {
    if (grouped[location.locationName]) {
      grouped[location.locationName].stock += location.stock
    } else {
      grouped[location.locationName] = { ...location }
    }
  })
  return Object.values(grouped)
}

const sortLocations = (locations: ProductLocation[]) => {
  return locations.sort((a, b) => {
    const parse = (str: string) => {
      const match = str.match(/P(\d+)-?(R(\d+))?-N(\d+)-U(\d+)/)
      if (match) {
        return [
          parseInt(match[1], 10), // P
          parseInt(match[3] || '0', 10), // R (optional)
          parseInt(match[4], 10), // N
          parseInt(match[5], 10) // U
        ]
      }
    }

    const parsedA = parse(a.locationName)
    const parsedB = parse(b.locationName)
    if (parsedA && parsedB) {
      for (let i = 0; i < parsedA.length; i++) {
        if (parsedA[i] !== parsedB[i]) {
          return parsedA[i] - parsedB[i]
        }
      }
    }
    return 0
  })
}

export const pickingListLoader = async ({ params }) => {
  const order = await getOrder(`${params.orderId}`)
  return {
    ...order,
    products: order.products.map((prod) => {
      const locations = groupLocations(prod.locations || [])
      return { ...prod, locations: sortLocations(locations) }
    })
  }
}
