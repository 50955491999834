import { atomWithStorage } from 'jotai/utils'
import type {
  OutputOrderProduct,
  CreatePickData
} from 'types/inventory-transfer/model'

export const selectedProductAtom = atomWithStorage<
OutputOrderProduct | undefined
>('selectedProductAtom', undefined)

export const pendingPickAtom = atomWithStorage<CreatePickData | undefined>(
  'pendingPick',
  undefined
)
