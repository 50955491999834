import { getProductLots } from 'client/receipt/receipt.client'
import type { GetProductLotsResponse } from 'types/receipt/model'

export const isProductLotsData = (
  data: unknown
): data is GetProductLotsResponse => {
  const hasAllProperties =
    !!data &&
    typeof data === 'object' &&
    'totalReceived' in data &&
    'totalRejected' in data &&
    'totalMissing' in data &&
    'totalReceivedBoxes' in data &&
    'totalRejectedBoxes' in data &&
    'totalMissingBoxes' in data &&
    'lots' in data
  if (!hasAllProperties) {
    return false
  }

  if (!Array.isArray(data.lots)) {
    return false
  }

  return data.lots.every((lot) => {
    return (
      lot &&
      typeof lot === 'object' &&
      'lotCode' in lot &&
      'expirationDate' in lot &&
      'units' in lot &&
      'boxes' in lot
    )
  })
}

export const productLotsLoader = async ({ params }) => {
  return await getProductLots(
    String(params.receiptId),
    String(params.productId)
  )
}
