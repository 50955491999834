import { useTranslation } from 'react-i18next'

import {
  ToolbarMolecule,
  Icons,
  PageContentAtom,
  AtlasBodySubtitle,
  BottomGroupMolecule
} from '@justomx/atlas-components'
import { useOrderDetailData } from './OrderDetail.hooks'
import type { ProductDetail } from 'types/receipt/model'
import TabGroupMolecule from 'components/molecules/TabGroup.molecule'
import { CardInfoMolecule } from 'components/molecules/CardInfo.molecule'
import { ScannerMolecule } from 'components/molecules/Scanner.molecule'
import { ConfirmationDialogMolecule } from 'components/molecules/ConfirmationDialog.molecule'
import {
  defaultActiveTab,
  getDefaultMissingOption,
  missingOptions
} from './OrderDetail.helpers'
import LoaderAtom from 'components/atoms/Loader.atom'
import { OptionsDialogOrganism } from 'components/organisms/OptionsDialog.organism'
import { DataCardMolecule } from 'components/molecules/DataCard.molecule'
import { FillerAtom } from 'components/atoms/Filler.atom'
import { MEASURE_UNITS, PRODUCT_STATUS, RECEIPTS } from 'utils/constants'
import { InputDialogOrganism } from 'components/organisms/InputDialog.organism'

const OrderDetail = () => {
  const { t } = useTranslation('global')
  const {
    orderId,
    products,
    tab,
    orderDetail,
    missingsDialogRef,
    fullMissingDialogRef,
    missingDialogRefValue,
    loading,
    isFullMissing,
    mode,
    upcDialog,
    receiptType,
    actions
  } = useOrderDetailData()

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}

      <ToolbarMolecule
        title={t('receipt.order-id', { orderId })}
        actionIcon={Icons.ArrowBack}
        onAction={actions.goToReceipt}
        secondaryActionIcon={Icons.IcKeyboardPrimary}
        onSecondaryAction={actions.onSelectKeyboard}
        thirdActionIcon={Icons.IcPalletPrimary}
        onThirdAction={actions.goToContainersPage}
      />

      <PageContentAtom>
        <CardInfoMolecule
          icon={Icons.IcWarehouseIcon}
          description={orderDetail.provider}
          subtitle={
            orderDetail.chaveAcesso
              ? t('receipt.chave-accesso', { chave: orderDetail.chaveAcesso })
              : undefined
          }
          subtitleIcon={Icons.IcKey}
        />

        <TabGroupMolecule
          tabs={actions.getTabs()}
          defaultActiveTab={defaultActiveTab}
          onSelectTab={actions.onSelectTab}
        />
        {products[tab]?.map((product, idx) => (
          <DataCardMolecule
            key={idx}
            imageUrl={product.imgUrl}
            title={product.description}
            subtitle={t('receipt-order-detail.upc', { upc: product.barcode })}
            footerLines={[
              {
                label: t(
                  product.measureUnit === MEASURE_UNITS.kg
                    ? 'receipt-order-detail.expected-grams'
                    : 'receipt-order-detail.boxes-counter',
                  {
                    total: product.totalUnits.toLocaleString('en-US'),
                    received: product.totalReceived.toLocaleString('en-US')
                  }
                ),
                value: ''
              }
            ]}
            showErrorState={product.isFullMissing}
            actionIcon={
              receiptType === RECEIPTS.transfers &&
              product.status === PRODUCT_STATUS.pending
                ? undefined
                : product.status === PRODUCT_STATUS.pending ||
                    product.isFullMissing
                  ? Icons.IcReportPrimary
                  : Icons.IcViewIcon
            }
            action={() => {
              actions.onSelectProduct(product)
            }}
            disableCopy={mode !== 'dev'}
          />
        ))}

        <FillerAtom />
        <BottomGroupMolecule>
          <ScannerMolecule
            cardMode={true}
            label={t('receipt-order-detail.scan-product')}
            onScan={actions.scanProduct}
          ></ScannerMolecule>
        </BottomGroupMolecule>

        <OptionsDialogOrganism
          dialogRef={missingsDialogRef}
          title={t('receipt-missing-dialog.missing-type')}
          options={missingOptions(t)}
          defaultOption={getDefaultMissingOption()}
          confirmAction={actions.onSelectMissingType}
        />
        <ConfirmationDialogMolecule
          dialogRef={fullMissingDialogRef}
          confirmText={t('commons.continue')}
          onConfirm={async () => {
            await actions.onConfirmFullMissing(
              missingDialogRefValue as ProductDetail
            )
          }}
          cancelText={t('commons.cancel')}
        >
          <AtlasBodySubtitle>
            {t(
              isFullMissing
                ? 'receipt-order-detail.undo-full-missing'
                : 'receipt-order-detail.full-missing'
            )}
          </AtlasBodySubtitle>
        </ConfirmationDialogMolecule>
        <InputDialogOrganism
          dialogRef={upcDialog}
          title={t('receipt-order-detail.manual-upc')}
          textboxLabel={t('receipt-order-detail.upc-textbox')}
          confirmLabel={t('commons.enter')}
          cancelLabel={t('commons.cancel')}
          confirmAction={actions.onConfirmManualUpc}
        />
      </PageContentAtom>
    </>
  )
}

export default OrderDetail
