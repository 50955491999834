import { type LoggedUser, UserManagment } from '@justomx/webview-commons'

const stgToken = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTEzLCJzZWN1cml0eUNvZGUiOiIiLCJpYXQiOjE3MDg2MTIwODcsImV4cCI6MTcyNDE2NDA4NywiYXVkIjoiaHR0cHM6Ly93d3cua292aXguY28vIiwiaXNzIjoiS292aXgiLCJzdWIiOiJLb3ZpeCBDbGllbnQifQ.CBnTiN2FUD3zED-6xLPUBHnAjYDNp781gIrlVCmBmulmzG0kB0xZseyBZMQkUOH1QyY_TzX6ocHmIqvJsHCzWTMHKIGnNFHJptf0BnZBNuloTgNN5yF-A9BmHwHcp4-CCEmvp6p8z8VUTmrKCstOoo4Bi5imPPCUrYqQSdZDniSplcgYF9MTpC3FEddWY27QYXYwfu-VxmopQTLn1KMkom8BY7Jiy0EFQRo_oRJR0A2p-WrtJeLLTVMPaTOXH7KuRLlCxMt7WfXEDzyFrzZihWDQ-RcgucIExeBZmIgXrSRwGxEeVk4UQQK-l7vn49cwrDQbRhctS40bLAMaeHHInWTebRR5Pj04tLmQ6CDcFRtNYi9YZvhGu3_CASMzm1xLywwdcdla8UCVlzWoGbYnSxaWII99FhZgfxf4dvEwu8YQu3o6vldO7l1KKGfq5XsHyiinn85nKEZMyUfhtplBXsDmljlQsJfexjpHIW1cjUtqimyeGe831OsGu6RbrSgFE9JLJ_RADf83je_SVGqQtygj3V3-j6ElzOrYshIBEYxJMWc2WM5_EvsqFANZCuwX1sZXx5DINRJvWB9rkamh1EUaERmMI2q7wiLc7HWcG2TA5CKiej2ses-0H9G2U4GFRzVTiLAhyyqebgy6irUq09Vk2vEuSpOcq98XyzxEEiw'

export interface CommonHeaders {
  'x-hardcoded': boolean
  authorization: string
  'x-authorization-wms': string
  'x-authorization-core': string
  'x-justo-country': string
  'x-justo-warehouse'?: string
}

export const getUser = () => {
  let user: LoggedUser | undefined
  try {
    user = UserManagment.getCurrentUser()
  } catch {
    user = undefined
  }
  return user
}

export const getCommonHeaders = (): CommonHeaders => {
  const user = getUser()
  const metadata = (window as any)?.WVMetadata

  if (user) {
    return {
      'x-hardcoded': false,
      authorization: user.authorizationWms,
      'x-authorization-wms': user.authorizationWms,
      'x-authorization-core': user.authorizationCore,
      'x-justo-country': user.country?.toUpperCase(),
      'x-justo-warehouse': user.warehouse?.toUpperCase()
    }
  } else if (metadata) {
    return {
      'x-hardcoded': false,
      authorization: metadata['x-authorization-wms'],
      'x-authorization-wms': metadata['x-authorization-wms'],
      'x-authorization-core': metadata['x-authorization-core'],
      'x-justo-country': metadata['x-justo-country']?.toUpperCase(),
      'x-justo-warehouse': metadata['x-justo-warehouse']?.toUpperCase()
    }
  }

  console.log('Metadata of WV is undefined, using default values.')
  return {
    'x-hardcoded': true,
    authorization: stgToken,
    'x-authorization-wms': stgToken,
    'x-authorization-core': stgToken,
    'x-justo-country': 'MX',
    'x-justo-warehouse': 'PP'
  }
}

export function getCurrentCountry () {
  const user = getUser()
  const metadata = (window as any)?.WVMetadata

  if (user) {
    return user.country?.toUpperCase()
  } else if (metadata) {
    return metadata['x-justo-country']?.toUpperCase()
  }

  console.log('Metadata of WV is undefined, using default values.')
  return 'MX'
}

export function getCurrentWarehouse (): string {
  const user = getUser()
  const metadata = (window as any)?.WVMetadata

  if (user) {
    return user.warehouse?.toUpperCase()
  } else if (metadata) {
    return metadata['x-justo-warehouse']?.toUpperCase()
  }

  console.log('Metadata of WV is undefined, using default values.')
  return 'PP'
}
