import {
  CardComponent,
  AtlasH1,
  AtlasCaption,
  AtlasH2,
  AtlasH3
} from '@justomx/atlas-components'

interface CounterCardProps {
  title: string
  subtitle: string
  titleSize: string
}

const CounterTitle = ({ size, title }: { size: string, title: string }) => {
  return {
    h1: <AtlasH1>{title}</AtlasH1>,
    h2: <AtlasH2>{title}</AtlasH2>,
    h3: <AtlasH3>{title}</AtlasH3>
  }[size]
}

export const CounterCardAtom = (props: CounterCardProps) => {
  const { title, subtitle, titleSize } = props

  return (
    <CardComponent $variant={'highlight'} $centered>
      <CounterTitle title={title} size={titleSize} />
      <AtlasCaption>{subtitle}</AtlasCaption>
    </CardComponent>
  )
}
