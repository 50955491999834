import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Icon, Icons } from '@justomx/atlas-components'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { FabButtonAtom } from 'components/atoms/FabButton.atom'
import LazyImageThumbnailAtom from 'components/atoms/LazyImageThumbnail.atom'
import { signFileUrls } from 'client/receipt/receipt.client'

interface S3ImagesPreviewProps {
  s3Keys: string[]
  imgSize?: number
  removePhoto?: (index: number) => void
  gap?: number
  margin?: number
}

const PhotosContainer = styled(FlexRowAtom)<{ $margin: number }>`
  flex-wrap: wrap;
  margin-left: ${({ $margin }) => $margin}px;
  margin-right: ${({ $margin }) => $margin}px;
  justify-content: center;
`

const ImageWrapper = styled.div`
  position: relative;
  width: fit-content;
`

const CloseIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const useSignedUrls = (keys: string[]) => {
  const [signedUrls, setSignedUrls] = useState<string[]>([])
  const [loading, setLoading] = useState(true)

  const fetchSignedUrls = async (keys: string[]) => {
    try {
      const response = await signFileUrls(keys)
      setSignedUrls(response)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (keys.length === 0) {
      setLoading(false)
      return
    }

    setLoading(true)
    fetchSignedUrls(keys)
      ?.then(() => {})
      .catch(() => {})
  }, [keys])

  return { signedUrls, loading }
}

export const S3ImagesPreviewMolecule = React.memo(
  (props: S3ImagesPreviewProps) => {
    const { s3Keys, imgSize, gap = 12, margin = 16, removePhoto } = props
    const { signedUrls } = useSignedUrls(s3Keys)

    return (
      <PhotosContainer $gap={gap} $margin={margin}>
        {signedUrls.map((signedUrl, index) => (
          <ImageWrapper key={index}>
            <LazyImageThumbnailAtom size={imgSize || 80} src={signedUrl} />
            {removePhoto && (
              <CloseIcon>
                <FabButtonAtom
                  $size={32}
                  $variant="secondary"
                  onClick={() => {
                    removePhoto(index)
                  }}
                >
                  <Icon src={Icons.CloseIcon} />
                </FabButtonAtom>
              </CloseIcon>
            )}
          </ImageWrapper>
        ))}
      </PhotosContainer>
    )
  }
)
S3ImagesPreviewMolecule.displayName = 'S3ImagesPreviewMolecule'
