import { useEffect, useState } from 'react'
import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useNavigate } from 'react-router-dom'

import type {
  OutputOrderProduct,
  PickData
} from 'types/inventory-transfer/model'
import { useRequiredParams } from 'hooks/params.hook'
import { normalizeEanForCapture, extractWeight } from 'utils/helpers'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import {
  updateOrderStatus,
  deletePick,
  updateMissing
} from 'client/inventory-transfer/inventory-transfer.client'
import { toastError, toastSuccess } from 'utils/toast'
import { ERRORS } from 'utils/constants'
import { useGoToInventoryTransfer } from '../InventoryTransfer.navigator'
import { useGoToPickProduct } from '../PickProduct/PickProduct.navigator'
import { selectedProductAtom } from '../InventoryTransfer.state'
import { isOutputOrder } from './PickingList.loader'

export const usePickingList = () => {
  const { t } = useTranslation('global')
  const setProduct = useSetAtom(selectedProductAtom)
  const order = useLoaderData()
  const navigate = useNavigate()
  const inventoryTransfer = useGoToInventoryTransfer()
  const pickProduct = useGoToPickProduct()
  const [productsToPick, setProductsToPick] = useState<OutputOrderProduct[]>([])
  const [pickedProducts, setPickedProducts] = useState<OutputOrderProduct[]>([])
  const [loading, setLoading] = useState(false)
  const { orderId, docId } = useRequiredParams('orderId', 'docId')

  if (!isOutputOrder(order)) {
    throw new Error(t('inventory-transfer.detail.type-error'))
  }

  useEffect(() => {
    if (order) {
      const picked: OutputOrderProduct[] = []
      const toPick: OutputOrderProduct[] = []
      order.products.forEach((product) => {
        if (product.picks.length || product.isFullMissing) {
          picked.push(product)
        } else {
          toPick.push(product)
        }
      })
      setProductsToPick(toPick)
      setPickedProducts(picked)
    }
  }, [order])

  const { dialogRef, open: openDialog, value } = useConfirmationDialog()

  const onClosePage = () => {
    inventoryTransfer.go()
  }

  const validateProduct = (
    barcode: string,
    showNotFoundAlert = true
  ): { toPick?: OutputOrderProduct, error: string } => {
    const picked = pickedProducts.find((prod) => prod.barcode === barcode)
    if (picked) {
      toastError(
        t('inventory-transfer.detail.already-picked', { upc: barcode })
      )
      return { error: ERRORS.alreadyPicked }
    }
    const toPick = productsToPick.find((prod) => prod.barcode === barcode)
    if (!toPick) {
      if (showNotFoundAlert) {
        toastError(
          t('inventory-transfer.detail.not-existing-upc', { upc: barcode })
        )
      }
      return { error: ERRORS.upcNotFound }
    }
    return { error: '', toPick }
  }

  const onScanProduct = (upc: string) => {
    const result = validateProduct(upc, false)
    let toPick = result.toPick
    let grams: number | null = null
    if (result.error === ERRORS.upcNotFound) {
      const barcode = normalizeEanForCapture(upc)
      grams = extractWeight(upc)

      const resultWeighable = validateProduct(barcode)
      if (resultWeighable.error.length) {
        return
      }
      toPick = resultWeighable.toPick
    } else if (result.error.length) return

    setProduct(toPick)
    if (grams) {
      pickProduct.go(orderId, docId, grams)
    } else {
      pickProduct.go(orderId, docId)
    }
  }

  const onFinishPicking = async () => {
    try {
      setLoading(true)
      await updateOrderStatus(orderId, 'completed')
      toastSuccess('Se finalizó la transferencia correctamente.')
      inventoryTransfer.go()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onDeletePick = async (product: OutputOrderProduct) => {
    const pickId = product.picks[0]?.pickId
    if (!pickId) {
      return
    }
    try {
      setLoading(true)
      await deletePick(orderId, product.barcode, pickId)
      navigate(0)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const openMissingDialog = (product: OutputOrderProduct) => {
    openDialog(product)
  }

  const onConfirmFullMissing = async () => {
    const prod = value as OutputOrderProduct
    try {
      setLoading(true)
      await updateMissing(orderId, prod.barcode)
      navigate(0)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const getPickedQuantity = (picks: PickData[]) => {
    return picks.reduce((prev, acc) => prev + acc.quantity, 0)
  }

  const getFooterLines = (product: OutputOrderProduct) => {
    const quantity = getPickedQuantity(product.picks || [])
    const footerLines = [
      {
        label: t('inventory-transfer.detail.picked-quantity'),
        value: product.isWeighted ? `${quantity}g` : `${quantity}`
      }
    ]
    if (product.picks[0]) {
      footerLines.push({
        label: t('inventory-transfer.detail.location'),
        value: product.picks[0].locationName
      })
    }
    return footerLines
  }

  const getLocations = (product: OutputOrderProduct) => {
    const { locations } = product
    if (!locations?.length) return []
    if (locations[0].stock >= product.quantity) {
      return [locations[0]]
    } else {
      return locations.slice(0, 3)
    }
  }

  return {
    orderId,
    pickedProducts,
    productsToPick,
    loading,
    dialogRef,
    selectedProduct: value as OutputOrderProduct,
    actions: {
      onClosePage,
      onScanProduct,
      onFinishPicking,
      onDeletePick,
      onConfirmFullMissing,
      openMissingDialog,
      getFooterLines,
      getLocations
    }
  }
}
