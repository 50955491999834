import { AnalyticsBrowser } from '@segment/analytics-next'
import { getCommonHeaders } from '../client/utilities.client-helper'
import { getUserInfo } from '../client/user/user.client'
import type { UserInfo } from 'types/commons/model'

let userId: string
let userInfo: UserInfo

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.SEGMENT_TOKEN || ''
})

const extractMail = async () => {
  return await new Promise<string>((resolve) => {
    const handler = setInterval(async () => {
      const a = getCommonHeaders()
      clearInterval(handler)

      try {
        const payload = JSON.parse(
          atob(a['x-authorization-core'].split(' ')[1].split('.')[1])
        )
        const user: UserInfo = await getUserInfo()
        userInfo = user
        console.info('userInfo', userInfo)
        resolve(user?.username ? 'email:' + user.username : 'id:' + payload.id)
      } catch (e) {
        resolve('error:parse_error@justo.mx')
      }
    }, 200)
  })
}

const setUser = async () => {
  try {
    userId = await extractMail()
    await analytics.identify(userId)
  } catch (error) {
    console.error('Error identifying user', error)
  }
}

export const getUser = () => {
  return userId
}

export const getCurrentUser = () => {
  console.info('getCurrentUser', userInfo)
  return userInfo
}

setUser()
  .then(() => {})
  .catch(() => {})
