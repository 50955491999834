import {
  getCurrentCountry,
  getCurrentWarehouse
} from 'client/utilities.client-helper'
import { warehouseLPNCodes } from './constants'

export const isValidLPNScan = (scanned: string) => {
  return /[A-Z]{2,3}-LPN-\d+/.test(scanned)
}

export const isValidLPNWarehouse = (lpn: string) => {
  const country = getCurrentCountry()
  const warehouse = getCurrentWarehouse()
  const warehouseLPN = lpn.split('-').shift()?.toUpperCase() || ''

  const codesByCountry = warehouseLPNCodes[country]
  if (!codesByCountry) return true

  const warehouseCode: string = codesByCountry[warehouseLPN]
  if (warehouseCode) {
    return warehouseCode === warehouse
  }
  return true
}

export const isEanWeighable = (ean: string): boolean => {
  return ean.startsWith('2')
}

export const normalizeEanForCapture = (ean: string) => {
  if (isEanWeighable(ean)) {
    return ean.substring(0, 7)
  }
  return ean
}

export const extractWeight = (ean: string): number | null => {
  if (
    ean == null ||
    ean.length !== 13 ||
    !/^\d+$/.test(ean) ||
    !isEanWeighable(ean)
  ) {
    return null
  }
  return parseInt(ean.substring(7, 12))
}
