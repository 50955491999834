import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import { useRequiredParams } from 'hooks/params.hook'
import { createPick } from 'client/inventory-transfer/inventory-transfer.client'
import { isValidLPNScan } from 'utils/helpers'
import { toastError, toastSuccess } from 'utils/toast'
import { pendingPickAtom } from '../InventoryTransfer.state'
import { useGoToPickingList } from '../PickingList/PickingList.navigator'

export const useScanContainer = () => {
  const { t } = useTranslation('global')
  const { orderId, docId } = useRequiredParams('orderId', 'docId')
  const [pendingPick, setPendingPick] = useAtom(pendingPickAtom)
  const [loading, setLoading] = useState(false)
  const pickingList = useGoToPickingList()
  const navigate = useNavigate()

  const onGoBack = () => {
    navigate(-1)
  }

  const onScanContainer = async (lpn: string) => {
    if (!pendingPick || loading) {
      return
    }
    if (!isValidLPNScan(lpn)) {
      toastError(t('receipt-scan-container.invalid-input', { lpn }))
      return
    }

    pendingPick.containerName = lpn
    try {
      setLoading(true)
      await createPick(orderId, pendingPick)
      toastSuccess(t('inventory-transfer.pick.success'))
      setPendingPick(undefined)
      pickingList.go(orderId, docId)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return { actions: { onGoBack, onScanContainer }, loading }
}
