import { Warehouse, getWarehouseFromLocation } from "./warehouses"
import { getZoneFromLocation, Zone, ZoneType } from "./zones"

type FullLocationInfo = LocationInfo & {
  warehouse: Warehouse | undefined,
  zone: Zone | undefined
}

type LocationInfo = {
  chamber?: number,
  aisle?: number,
  rack?: number,
  level?: number,
  unit?: number,
  dock?: number,
  channel?: number,
  grid?: number,
}

const getInfo = (location: string[], zoneType: ZoneType): LocationInfo => {
  const res: LocationInfo = {};

  for (const property of location) {
    const initial = property.substring(0,1);
    const number = property.substring(1);

    switch (initial.toUpperCase()) {
      case 'P':
        res['aisle'] = parseInt(number);
        break;
      case 'R':
        res['rack'] = parseInt(number);
        break;
      case 'N':
        res['level'] = parseInt(number);
        break;
      case 'U':
        res['unit'] = parseInt(number);
        break;
      case 'C':
        if (zoneType === 'Picking') res['chamber'] = parseInt(number);
        if (zoneType === 'Otras') res['channel'] = parseInt(number);
        break;
      case 'M':
        res['dock'] = parseInt(number);
        break;
      case 'G':
        res['grid'] = parseInt(number);
        break;
    }
  }

  return res;
}

export const getFullInfoFromLocation = (location: string): FullLocationInfo | undefined => {
  const parsedLocation = location.trim().split('-');

  const warehouse = getWarehouseFromLocation(location);
  const zone = getZoneFromLocation(location);

  const position = parsedLocation.slice(2);
  const locationInfo = zone === undefined ? {} : getInfo(position, zone.zoneType);

  return {
    warehouse,
    zone,
    ...locationInfo
  }
}