import { atomWithStorage } from 'jotai/utils'
import type { ContainerToMove, ProductDetail } from 'types/receipt/model'

export const selectedProductAtom = atomWithStorage<ProductDetail | undefined>(
  'selectedProductAtom',
  undefined
)
export const scannedContainer = atomWithStorage<string | undefined>(
  'scannedContainer',
  undefined
)
export const containerToMoveAtom = atomWithStorage<ContainerToMove | undefined>(
  'containerToMove',
  undefined
)
export const containerTypeAtom = atomWithStorage<string>(
  'containerTypeAtom',
  ''
)
export const currentOrderAtom = atomWithStorage<string | undefined>(
  'currentOrderAtom',
  undefined
)
