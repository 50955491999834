import { getContainers } from 'client/receipt/receipt.client'
import type { ContainersStatus } from 'types/receipt/model'

export const isContainerStatus = (data: unknown): data is ContainersStatus => {
  const hasAllProps =
    data &&
    typeof data === 'object' &&
    'containers' in data &&
    'allProductsReceived' in data
  if (!hasAllProps) {
    return false
  }

  if (!Array.isArray(data.containers)) {
    return false
  }

  return data.containers.every((container) => {
    return (
      container &&
      typeof container === 'object' &&
      'containerName' in container &&
      'containerStatus' in container &&
      'productIds' in container
    )
  })
}

export const moveContainersLoader = async ({ params }) => {
  return await getContainers(`${params.receiptId}`)
}
