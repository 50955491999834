import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import type { ContainerToMove } from 'types/receipt/model'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  CardComponent,
  Divider,
  Icon,
  Icons
} from '@justomx/atlas-components'

interface ContainerCardProps {
  container: ContainerToMove
}

const LineInfo = styled(FlexRowAtom)`
  align-items: center;
  gap: 8px;
`

export const ContainerCardMolecule = (props: ContainerCardProps) => {
  const { t } = useTranslation('global')
  const { container } = props

  return (
    <CardComponent>
      <AtlasBodySubtitle>
        {t('receipt-containers.container', {
          container: container.containerName
        })}
      </AtlasBodySubtitle>
      <Divider />

      { container.containerLocation
        ? <LineInfo>
            <Icon src={Icons.IcZonaIcon } />
            <AtlasBodyMain>
              {t('receipt-containers.location', { location: container.containerLocation })}
            </AtlasBodyMain>
          </LineInfo>
        : null }

      <LineInfo>
        <Icon src={Icons.IcMoveDownBlack24dp1Icon} />
        <AtlasBodyMain>
          {t('receipt-containers.skus', {
            totalSKU: container.productIds.length
          })}
        </AtlasBodyMain>
      </LineInfo>
    </CardComponent>
  )
}
