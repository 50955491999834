export type Zone = {
  wmsName: ZoneName;
  coreDescription: string;
  zoneType: ZoneType;
  icon?: string;
  color?: string;
}

export type ZoneName = 'AV' | 'ABA' | 'LAB' | 'CONG' | 'REF' | 'GRA' | 'DELI' | 'FAR' | 'PF' | 'INS' | 'SMP' | 'ALM' | 'STK' | 'STG' | 'STI'
                          | 'CR' | 'CC' | 'SFV' | 'RE' | 'PA' | 'LY' | 'LINV';
export type ZoneType = 'Picking' | 'Storage' | 'Otras';
export const isZoneName = (str: string): str is ZoneName => {
  return Boolean(Zones.find(e => e.wmsName === str))
}

export const Zones: Zone[] = [
  {
    wmsName: 'AV',
    coreDescription: 'Alto Valor',
    zoneType: 'Picking',
    icon: 'https://static.justo.mx/images/atlas/alto_valor_material.png',
    color: '#EA80FC'
  },
  {
    wmsName: 'ABA',
    coreDescription: 'Abarrotes',
    zoneType: 'Picking',
    icon: 'https://static.justo.mx/images/atlas/abarrotes_material.png',
    color: '#FF6D76'
  },
  {
    wmsName: 'LAB',
    coreDescription: 'Laboratorio',
    zoneType: 'Picking',
    icon: 'https://static.justo.mx/images/atlas/laboratorio_material.png',
    color: '#9CCC65'
  },
  {
    wmsName: 'CONG',
    coreDescription: 'Congelados',
    zoneType: 'Picking',
    icon: 'https://static.justo.mx/images/atlas/congelados_material.png',
    color: '#448AFF'
  },
  {
    wmsName: 'REF',
    coreDescription: 'Refrigerados',
    zoneType: 'Picking',
    icon: 'https://static.justo.mx/images/atlas/refrigerados_material.png',
    color: '#71E2F1'
  },
  {
    wmsName: 'GRA',
    coreDescription: 'Granel',
    zoneType: 'Picking',
    icon: 'https://static.justo.mx/images/atlas/granel.png',
    color: '#FAEE82'
  },
  {
    wmsName: 'DELI',
    coreDescription: 'Deli',
    zoneType: 'Picking',
    icon: 'https://static.justo.mx/images/atlas/panaderia_material.png',
    color: '#FF9D6E',
  },
  {
    wmsName: 'FAR',
    coreDescription: 'Farmacia',
    zoneType: 'Picking',
    icon: 'https://static.justo.mx/images/atlas/farmacia.png',
    color: '#65CC82'
  },
  {
    wmsName: 'PF',
    coreDescription: 'Pick Floor',
    zoneType: 'Picking'
  },
  {
    wmsName: 'INS',
    coreDescription: 'Insumos',
    zoneType: 'Picking'
  },
  {
    wmsName: 'SMP',
    coreDescription: 'Sampling',
    zoneType: 'Picking'
  },
  { 
    wmsName: 'ALM',
    coreDescription: 'Almacenaje',
    zoneType: 'Storage'
  },
  {
    wmsName: 'STK',
    coreDescription: 'Stock Alto Valor',
    zoneType: 'Storage'
  },
  {
    wmsName: 'STG',
    coreDescription: 'Stock Granel',
    zoneType: 'Storage'
  },
  {
    wmsName: 'STI',
    coreDescription: 'Stock Insumos',
    zoneType: 'Storage'
  },
  {
    wmsName: 'CR',
    coreDescription: 'Stock Refrigerados',
    zoneType: 'Storage'
  },
  {
    wmsName: 'CC',
    coreDescription: 'Stock Congelados',
    zoneType: 'Storage'
  },
  {
    wmsName: 'SFV',
    coreDescription: 'Stock Laboratorio',
    zoneType: 'Storage'
  },
  {
    wmsName: 'RE',
    coreDescription: 'Recibo',
    zoneType: 'Otras'
  },
  {
    wmsName: 'PA',
    coreDescription: 'Pre-almacenamiento',
    zoneType: 'Otras'
  },
  {
    wmsName: 'LY',
    coreDescription: 'Lay Out',
    zoneType: 'Otras'
  },
  {
    wmsName: 'LINV',
    coreDescription: 'Logistica inversa',
    zoneType: 'Otras'
  }
]

export const getZoneByName = (str: string): Zone | undefined => {
  return Zones.find((zone) => zone.coreDescription === str);
}

export const getZoneByWmsName = (name: string): Zone | undefined => {
  return Zones.find((zone) => zone.wmsName.toLowerCase() === name.toLowerCase().trim());
}

export const getZoneByZoneType = (type: string): Zone[] | undefined => {
  return Zones.filter((zone) => zone.zoneType === type);
}

export const getZoneFromLocation = (location: string): Zone | undefined => {
  const zoneWmsName = location.split('-')[1].toUpperCase();
  return getZoneByWmsName(zoneWmsName);
}