import { type ChangeEvent } from 'react'
import styled from 'styled-components'
import type { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  AtlasBodySubtitle,
  AtlasBodyTitle,
  CardComponent,
  Icon,
  Icons
} from '@justomx/atlas-components'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import type { FormikLotFormValues, ProductDetail } from 'types/receipt/model'
import { FlexColumnAtom } from 'components/atoms/FlexColumn.atom'
import { FabButtonAtom } from 'components/atoms/FabButton.atom'
import { FormikTextFieldOrganism } from './FormikTextField.organism'
import { MEASURE_UNITS } from 'utils/constants'

interface LotFormProps {
  formConfig: FormikProps<FormikLotFormValues>
  containerName: string
  product: ProductDetail
  remove: <X = any>(index: number) => X | undefined
}

const CardHeader = styled(FlexRowAtom)`
  justify-content: space-between;
  align-items: center;
`

export const LotFormOrganism = (props: LotFormProps) => {
  const { t } = useTranslation('global')
  const { formConfig, containerName, product, remove } = props

  const onDateChange = async (
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    let inputValue = event.target.value.replace(/\//g, '')
    if (inputValue.length >= 2 && inputValue.length <= 4) {
      inputValue =
        inputValue.substring(0, 2) +
        '/' +
        inputValue.substring(2) +
        (inputValue.length === 4 ? '/' : '')
    } else if (inputValue.length > 4) {
      inputValue =
        inputValue.substring(0, 2) +
        '/' +
        inputValue.substring(2, 4) +
        '/' +
        inputValue.substring(4)
    }
    await formConfig.setFieldValue(name, inputValue)
  }

  return (
    <FlexColumnAtom $gap={16}>
      {formConfig.values.forms.map((form, index) => (
        <CardComponent key={index} style={{ gap: '24px' }}>
          <CardHeader>
            <FlexColumnAtom>
              <AtlasBodyTitle>
                {t('receipt-lot-form.lot-number', { lotNumber: index + 1 })}
              </AtlasBodyTitle>
              <AtlasBodySubtitle>
                {form.containerName || containerName}
              </AtlasBodySubtitle>
            </FlexColumnAtom>

            <FabButtonAtom
              $variant="secondary"
              $size={40}
              type="button"
              disabled={index === 0}
              onClick={() => {
                remove(index)
              }}
            >
              <Icon
                src={index === 0 ? Icons.IcDeleteDark : Icons.IcDelete}
                size={20}
              />
            </FabButtonAtom>
          </CardHeader>

          <FlexColumnAtom $gap={24}>
            <FormikTextFieldOrganism
              type="text"
              name={`forms[${index}].lot`}
              label={t('receipt-lot-form.lot')}
              formConfig={formConfig}
            />

            <FormikTextFieldOrganism
              type="text"
              name={`forms[${index}].expirationDate`}
              label={t('receipt-lot-form.expiration')}
              formConfig={formConfig}
              customChange={async (event) => {
                await onDateChange(event, `forms[${index}].expirationDate`)
              }}
            />

            {product.measureUnit === MEASURE_UNITS.box && (
              <>
                <FormikTextFieldOrganism
                  type="number"
                  name={`forms[${index}].amountOfBoxes`}
                  label={t('receipt-lot-form.boxes')}
                  formConfig={formConfig}
                />
                <FormikTextFieldOrganism
                  type="number"
                  name={`forms[${index}].numberOfUnits`}
                  label={t(
                    product.measureUnit === 'KG'
                      ? 'receipt-lot-form.grams'
                      : 'receipt-lot-form.units'
                  )}
                  supportingText={t('receipt-lot-form.units-hint')}
                  formConfig={formConfig}
                />
              </>
            )}

            {product.measureUnit !== MEASURE_UNITS.box && (
              <>
                <FormikTextFieldOrganism
                  type="number"
                  name={`forms[${index}].numberOfUnits`}
                  label={t(
                    product.measureUnit === 'KG'
                      ? 'receipt-lot-form.grams'
                      : 'receipt-lot-form.units'
                  )}
                  formConfig={formConfig}
                />
                <FormikTextFieldOrganism
                  type="number"
                  name={`forms[${index}].amountOfBoxes`}
                  label={t('receipt-lot-form.boxes')}
                  formConfig={formConfig}
                />
              </>
            )}
          </FlexColumnAtom>
        </CardComponent>
      ))}
    </FlexColumnAtom>
  )
}
