import { useRequiredParams } from 'hooks/params.hook'
import { useNavigate } from 'react-router-dom'

export const useGoToContainerType = () => {
  const navigate = useNavigate()
  const { receiptType, receiptId, orderId } = useRequiredParams(
    'receiptType',
    'receiptId',
    'orderId'
  )

  return {
    go: () => {
      navigate(
        CONTAINER_TYPE_URL.replace(':receiptType', receiptType)
          .replace(':receiptId', receiptId)
          .replace(':orderId', orderId)
      )
    }
  }
}

export const CONTAINER_TYPE_URL =
  '/receipt/:receiptType/:receiptId/order/:orderId/container-type'
