import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { AtlasCaption, StackAtom } from '@justomx/atlas-components'

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000;
`

const SvgContainer = styled.svg`
  --uib-size: 40px;
  --uib-color: #e0bb00;
  --uib-bg-opacity: 0.1;
  height: var(--uib-size);
  width: var(--uib-size);
  overflow: visible;

  animation: spin 0.8s linear infinite;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

const SvgTrack = styled.circle`
  fill: none;
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
`

const SvgCar = styled.circle`
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 25, 75;
  stroke-dashoffset: 0;
  stroke-linecap: round;
`

const LoaderSvg = () => (
  <SvgContainer viewBox="0 0 40 40" height="40" width="40">
    <SvgTrack
      cx="20"
      cy="20"
      r="17.5"
      pathLength="100"
      strokeWidth="5px"
      fill="none"
    />
    <SvgCar
      cx="20"
      cy="20"
      r="17.5"
      pathLength="100"
      strokeWidth="5px"
      fill="none"
    />
  </SvgContainer>
)

interface LoaderAtomProps {
  children: string
}

const LoaderAtom = ({ children }: LoaderAtomProps) => {
  const [waitMessage, setWaitMessage] = useState<boolean>(false)

  useEffect(() => {
    const handler = setTimeout(() => {
      setWaitMessage(true)
    }, 2000)

    return () => {
      clearInterval(handler)
    }
  }, [setWaitMessage])

  return (
    <LoaderWrapper>
      <StackAtom $centered={true}>
        <LoaderSvg />
        {waitMessage && <AtlasCaption>{children}</AtlasCaption>}
      </StackAtom>
    </LoaderWrapper>
  )
}

export default LoaderAtom
