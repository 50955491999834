import { useTranslation } from 'react-i18next'
import {
  AtlasCaption,
  Icon,
  Icons,
  PageContentAtom,
  SnackbarInformationPanel,
  ToolbarMolecule
} from '@justomx/atlas-components'

import LoaderAtom from 'components/atoms/Loader.atom'
import { FillerAtom } from 'components/atoms/Filler.atom'
import { ScannerMolecule } from 'components/molecules/Scanner.molecule'
import { useScanContainer } from './ScanContainer.hooks'

const ScanContainerPage = () => {
  const { t } = useTranslation('global')
  const { actions, loading } = useScanContainer()

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
      <ToolbarMolecule
        title={t('receipt-scan-container.title')}
        actionIcon={Icons.CloseIcon}
        onAction={actions.onGoBack}
      />

      <PageContentAtom>
        <SnackbarInformationPanel>
          <Icon src={Icons.IcInformationIcon} />
          <AtlasCaption>{t('inventory-transfer.container.alert')}</AtlasCaption>
        </SnackbarInformationPanel>
        <FillerAtom />
        <ScannerMolecule
          label={t('receipt-scan-container.scan-label')}
          onScan={actions.onScanContainer}
        />
      </PageContentAtom>
    </>
  )
}

export default ScanContainerPage
