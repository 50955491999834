import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  BottomGroupMolecule,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  SecondaryButtonAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'
import { useContainerType } from './ContainerType.hooks'
import { CONTAINER_TYPES } from 'utils/constants'

const ContainerTypePage = () => {
  const { t } = useTranslation('global')
  const { actions } = useContainerType()

  return (
    <>
      <ToolbarMolecule
        title={t('receipt-container.title')}
        actionIcon={Icons.ArrowBack}
        onAction={actions.goBack}
      ></ToolbarMolecule>

      <PageContentAtom>
        <AtlasBodySubtitle>{t('receipt-container.label')}</AtlasBodySubtitle>
        <AtlasBodyMain>{t('receipt-container.text-partial')}</AtlasBodyMain>
        <AtlasBodyMain>{t('receipt-container.text-full')}</AtlasBodyMain>

        <BottomGroupMolecule>
          <SecondaryButtonAtom
            type="button"
            onClick={() => {
              actions.onSelectType(CONTAINER_TYPES.partial)
            }}
          >
            {t('receipt-container.partial')}
          </SecondaryButtonAtom>
          <PrimaryButtonAtom
            type="button"
            onClick={() => {
              actions.onSelectType(CONTAINER_TYPES.total)
            }}
          >
            {t('receipt-container.total')}
          </PrimaryButtonAtom>
        </BottomGroupMolecule>
      </PageContentAtom>
    </>
  )
}

export default ContainerTypePage
