import {
  DialogAtom,
  DialogButtonAtom,
  DialogContentAtom,
  DialogFooterAtom
} from 'components/atoms/Dialog.atom'
import { type MutableRefObject, useRef, type ReactNode, useState } from 'react'

export function useConfirmationDialog<T> () {
  const dialogRef = useRef<HTMLDialogElement | null>(null)
  const [value, setValue] = useState<T>()

  const open = (value?: T) => {
    setValue(value)
    dialogRef.current?.showModal()
  }

  const close = () => {
    dialogRef.current?.close()
  }

  return {
    dialogRef,
    open,
    close,
    value
  }
}

interface ConfirmationDialogProps<T> {
  dialogRef: MutableRefObject<HTMLDialogElement | null>
  children: ReactNode
  confirmText: string
  onConfirm: (value?: T) => void
  cancelText: string
  onCancel?: () => void
  value?: T
  confirmOnly?: boolean
}

export function ConfirmationDialogMolecule<X> ({
  dialogRef,
  children,
  confirmText,
  onConfirm,
  cancelText,
  onCancel,
  value,
  confirmOnly = false
}: ConfirmationDialogProps<X>) {
  const cancel = () => {
    dialogRef.current?.close()
    if (onCancel != null) {
      onCancel()
    }
  }

  const confirm = () => {
    dialogRef.current?.close()
    onConfirm(value)
  }

  return (
    <DialogAtom ref={dialogRef}>
      <DialogContentAtom>{children}</DialogContentAtom>
      <DialogFooterAtom>
        {!confirmOnly && <DialogButtonAtom onClick={cancel}>{cancelText}</DialogButtonAtom>}
        <DialogButtonAtom onClick={confirm} $primary>
          {confirmText}
        </DialogButtonAtom>
      </DialogFooterAtom>
    </DialogAtom>
  )
}
