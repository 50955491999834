import { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import type { FormikTemperatureValues } from 'types/receipt/model'
import { useRequiredParams } from 'hooks/params.hook'
import { getValidationSchema, mapFormValues } from './Temperature.helpers'
import { useGoToOrderDetail } from '../order-detail/OrderDetail.navigator'
import { isAnOrder } from '../order-detail/OrderDetail.loader'
import { Analytics } from 'analytics/analytics.events'
import { updateTemperature } from 'client/receipt/receipt.client'
import { toastSuccess } from 'utils/toast'

export const useTemperature = () => {
  const { t } = useTranslation('global')
  const { receiptId, receiptType } = useRequiredParams(
    'receiptId',
    'receiptType'
  )
  const navigate = useNavigate()
  const [validateRanges, setValidateRanges] = useState(true)
  const [loading, setLoading] = useState(false)
  const orderDetail = useGoToOrderDetail()
  const loaderData = useLoaderData()

  if (!isAnOrder(loaderData)) {
    throw new Error('receipt-temperature.type-error')
  }
  const [validationSchema, setValidationSchema] = useState(
    getValidationSchema(t, loaderData.zone, validateRanges)
  )

  const formConfig = useFormik<FormikTemperatureValues>({
    initialValues: mapFormValues(loaderData.zone, loaderData.temperature),
    validationSchema,
    onSubmit: async ({ temperature, outOfRangeApproved }) => {
      try {
        setLoading(true)
        const document = await updateTemperature(receiptId, +temperature)
        Analytics.receipt.registerTemperature({
          orderId: loaderData?.orderId,
          receiptType,
          temperature: +temperature,
          temperatureOutOfRange: outOfRangeApproved
        })

        toastSuccess(t('receipt-temperature.update-success'))
        orderDetail.go(receiptType, receiptId, document.docNum as string)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  })

  useEffect(() => {
    const validateRanges = !formConfig.values.outOfRangeApproved
    setValidateRanges(validateRanges)
  }, [formConfig.values])

  useEffect(() => {
    const newValidationSchema = getValidationSchema(
      t,
      loaderData.zone,
      validateRanges
    )
    setValidationSchema(newValidationSchema)
    formConfig.setFormikState((prevState) => ({
      ...prevState,
      validationSchema: newValidationSchema
    }))
  }, [validateRanges])

  useEffect(() => {
    formConfig
      .validateForm()
      .then(() => {})
      .catch(() => {})
  }, [validationSchema])

  const onClosePage = () => {
    navigate(-1)
  }

  return { t, formConfig, loading, actions: { onClosePage } }
}
