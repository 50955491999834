import { defer } from 'react-router-dom'
import { getOutputOrders } from 'client/inventory-transfer/inventory-transfer.client'
import type { OutputOrderData } from 'types/inventory-transfer/model'

export const isArrayOfOutputOrders = (
  data: unknown
): data is OutputOrderData[] => {
  if (!Array.isArray(data)) {
    return false
  }

  return data.every((order) => {
    return (
      order &&
      typeof order === 'object' &&
      'order' in order &&
      'status' in order &&
      'products' in order
    )
  })
}

export const inventoryTransferLoader = async () => {
  const getOrdersPromise = getOutputOrders()
  return defer({ orders: getOrdersPromise })
}
