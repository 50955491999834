import React from 'react'
import { Await, useNavigate } from 'react-router-dom'

import {
  PageContentAtom,
  CardComponent,
  AtlasBodyMain,
  BottomGroupMolecule,
  SecondaryButtonAtom,
  AtlasBodySubtitle,
  NavigationMenuOrganism,
  ErrorPage,
  ToolbarMolecule,
  Icons
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'

import type { OrderSummary } from 'types/receipt/model'
import LoaderAtom from 'components/atoms/Loader.atom'
import { FillerAtom } from 'components/atoms/Filler.atom'
import { useReceiptData } from './Receipt.hooks'
import { useSessionData } from 'hooks/session.hook'
import { Global } from 'utils/global'

const Receipt = () => {
  const { t } = useTranslation('global')
  const { data, actions } = useReceiptData()
  const { user, country, warehouse } = useSessionData()
  const navigate = useNavigate()

  return (
    <React.Suspense fallback={<LoaderAtom>{t('loader.message')}</LoaderAtom>}>
      <Await
        resolve={(data as any).orders}
        errorElement={
          <ErrorPage
            title={'Algo salió mal'}
            reason={'Error al cargar las órdenes'}
            action={() => {
              navigate(-1)
            }}
          ></ErrorPage>
        }
      >
        {(orders: OrderSummary[]) => (
          <>
            {!!user?.menuItems?.length && (
              <NavigationMenuOrganism
                title={t('receipt.title')}
                country={country}
                userEmail={user.username}
                userName={user.firstName}
                version="13.0.1"
                warehouseName={warehouse?.coreDescription || 'Pruebas'}
                onLogout={actions.logout}
                menuItems={user.menuItems || []}
                isMenuOpen={false}
              />
            )}

            {!user?.menuItems?.length && (
              <ToolbarMolecule
                title={t('receipt.title')}
                actionIcon={Icons.HamburgerIcon}
                onAction={Global.WV.openMenu}
              />
            )}

            <PageContentAtom>
              {orders.map((order, idx) => (
                <CardComponent
                  key={`order${idx}`}
                  onClick={() => {
                    actions.onSelectOrder(order)
                  }}
                >
                  <AtlasBodySubtitle>
                    {t('receipt.order-id', { orderId: order.orderId })}
                  </AtlasBodySubtitle>
                  {order.chaveAcesso !== null && (
                    <AtlasBodyMain>
                      {t('receipt.chave-accesso', {
                        chave: order.chaveAcesso
                      })}
                    </AtlasBodyMain>
                  )}
                  <AtlasBodyMain>
                    {t('receipt.provider', { provider: order.provider })}
                  </AtlasBodyMain>
                  <AtlasBodyMain>
                    {t('receipt.total-sku', { totalSKU: order.numberSkus })}
                  </AtlasBodyMain>
                </CardComponent>
              ))}

              {!orders.length
                ? <>
                    <AtlasBodySubtitle>{t('receipt.no-data-title')}</AtlasBodySubtitle>
                    <AtlasBodyMain>{t('receipt.no-data-help')}</AtlasBodyMain>
                  </>
                : null}

              <FillerAtom />
              <BottomGroupMolecule>
                <SecondaryButtonAtom onClick={actions.onSelectSync}>
                  {t('receipt.sync')}
                </SecondaryButtonAtom>
              </BottomGroupMolecule>
            </PageContentAtom>
          </>
        )}
      </Await>
    </React.Suspense>
  )
}

export default Receipt
