import { AtlasBodyMain, CardComponent, Icon } from '@justomx/atlas-components'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'

interface CardInfoProps {
  icon: string
  description: string
  subtitle?: string
  subtitleIcon?: string
}

export const CardInfoMolecule = (props: CardInfoProps) => {
  return (
    <CardComponent $variant="highlight">
      <FlexRowAtom $gap={8}>
        <Icon src={props.icon} size={15} />
        <AtlasBodyMain>{props.description}</AtlasBodyMain>
      </FlexRowAtom>

      {props.subtitle && (
        <FlexRowAtom $gap={8}>
          <Icon src={props.subtitleIcon} size={15} color='white' />
          <AtlasBodyMain>{props.subtitle}</AtlasBodyMain>
        </FlexRowAtom>
      )}
    </CardComponent>
  )
}
