import type { TFunction } from 'i18next'
import type { GenericOption, ProductDetail } from 'types/receipt/model'
import { getCurrentCountry } from 'client/utilities.client-helper'

export const groupByStatus = (
  data: ProductDetail[]
): Record<string, ProductDetail[]> => {
  return data.reduce((acc, item) => {
    const { status } = item
    if (!acc[status]) {
      acc[status] = []
    }
    acc[status].push(item)
    return acc
  }, {})
}

export const tabs = ['pending', 'finished']
export const defaultActiveTab = 'pending'
export const getDefaultMissingOption = () => {
  const country = getCurrentCountry()
  if (country === 'BR') {
    return 'rejection'
  } else {
    return 'fullMissing'
  }
}
export const missingOptions = (
  t: TFunction<'global', undefined>
): GenericOption[] => {
  const country = getCurrentCountry()
  if (country === 'BR') {
    return [{ id: 'rejection', label: t('receipt-missing-dialog.rejection') }]
  }

  return [
    { id: 'fullMissing', label: t('receipt-missing-dialog.full-missing') },
    { id: 'rejection', label: t('receipt-missing-dialog.rejection') }
  ]
}
