import * as Yup from 'yup'
import type { TFunction } from 'i18next'

import type { ProductDetail, RejectionSummary } from 'types/receipt/model'
import { MEASURE_UNITS } from 'utils/constants'

export const getMissingLimit = (
  product: ProductDetail | undefined,
  totals: RejectionSummary
) => {
  if (!product) {
    return { totalUnits: 0, boxes: 0 }
  }
  let totalUnits = product.totalUnits
  totalUnits -= totals.totalReceived + totals.totalRejected
  const boxes =
    product.quantity - (totals.totalReceivedBoxes + totals.totalRejectedBoxes)

  return { totalUnits, boxes }
}

const getUnitsByBoxesValidator = (
  t: TFunction<'global', undefined>,
  unitsOfMeasurment: number
) => {
  return (_: number, context: Yup.TestContext<Yup.AnyObject>) => {
    const { path, parent, createError } = context

    const boxes: number | undefined = parent.boxes
    if (!boxes) {
      return createError({
        path,
        message: t('validations.invalid-amount')
      })
    }
    const expectedUnits = boxes * unitsOfMeasurment
    if (parent.totalMissing !== expectedUnits) {
      return createError({
        path,
        message: t('validations.invalid-amount')
      })
    }
    return true
  }
}

export const getValidationSchema = (
  t: TFunction<'global', undefined>,
  maxAmount: number,
  maxBoxes: number,
  measureUnit: string,
  unitsOfMeasurment: number
) => {
  const amount = t('validations.invalid-amount')
  const required = t('validations.required')
  const limit = t('validations.greater-total-units')

  const boxesValidation = Yup.number().positive(amount).integer(amount)
  let totalMissing = Yup.number()
    .required(required)
    .positive(amount)
    .integer(amount)
    .max(maxAmount, limit)
  if (measureUnit === MEASURE_UNITS.box) {
    totalMissing = totalMissing.test(
      'unitsByBoxes',
      getUnitsByBoxesValidator(t, unitsOfMeasurment)
    )
  }

  return Yup.object({
    totalMissing,
    boxes: Yup.number().concat(
      measureUnit === MEASURE_UNITS.box
        ? boxesValidation.required(required).max(maxBoxes, limit)
        : boxesValidation.notRequired()
    )
  })
}
