import { getProductProcessed } from 'client/receipt/receipt.client'
import type { ReceiptProductProcessed } from 'types/receipt/model'

export const isAReceiptProductProcessed = (
  data: unknown
): data is ReceiptProductProcessed => {
  return (
    !!data &&
    typeof data === 'object' &&
    'ean' in data &&
    'sapProductId' in data &&
    'expectedQuantity' in data
  )
}

export const summaryLoader = async ({ params }) => {
  return await getProductProcessed(`${params.receiptId}`, `${params.productId}`)
}
