import type { FormikProps } from 'formik'
import styled from 'styled-components'

import {
  AtlasCaption,
  AtlasColor,
  TextBoxMolecule
} from '@justomx/atlas-components'
import { FlexColumnAtom } from 'components/atoms/FlexColumn.atom'

interface FormikTextFieldProps {
  type: 'text' | 'number' | 'date'
  name: string
  label: string
  formConfig: FormikProps<any>
  supportingText?: string
  customChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Error = styled(AtlasCaption)`
  padding-left: 16px;
  color: ${AtlasColor.Error};
`

export const FormikTextFieldOrganism = (props: FormikTextFieldProps) => {
  const { type, name, label, formConfig, supportingText, customChange } = props

  const getNestedProperty = (obj: any): string => {
    return name.split('.').reduce((o, key) => {
      if (key.endsWith(']')) {
        const [arrayKey, index] = key.slice(0, -1).split('[')
        return o?.[arrayKey]?.[parseInt(index, 10)]
      } else {
        return o?.[key]
      }
    }, obj)
  }

  const getError = (): string | null => {
    if (!formConfig.errors) {
      return null
    }

    const fieldErrors = getNestedProperty(formConfig.errors)
    const fieldIsTouched = getNestedProperty(formConfig.touched)

    if (fieldErrors && fieldIsTouched) {
      return fieldErrors
    }
    return null
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (customChange) {
      customChange(event)
    } else {
      formConfig.handleChange(event)
    }
  }

  const onFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setTimeout(() => {
      event.target.scrollIntoView({ behavior: 'instant', block: 'center' })
    }, 500)
  }

  return (
    <FlexColumnAtom>
      <TextBoxMolecule
        type={type}
        name={name}
        label={label}
        supportingText={supportingText}
        value={getNestedProperty(formConfig.values)}
        hasErrors={!!getError()}
        onChangeFullEvent={onChange}
        onBlur={formConfig.handleBlur}
        onFocus={onFocus}
        autocomplete="off"
      />
      {getError() && <Error>{getError()}</Error>}
    </FlexColumnAtom>
  )
}
