import { useTranslation } from 'react-i18next'

import {
  BottomGroupMolecule,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import TabGroupMolecule from 'components/molecules/TabGroup.molecule'
import { useMoveContainersData } from './MoveContainers.hooks'
import { ScannerMolecule } from 'components/molecules/Scanner.molecule'
import { ContainerCardMolecule } from 'components/molecules/ContainerCard.molecule'
import LoaderAtom from 'components/atoms/Loader.atom'
import { FillerAtom } from 'components/atoms/Filler.atom'

const MoveContainersPage = () => {
  const { t } = useTranslation('global')
  const { containers, actions, activeTab, enableFinish, loading } =
    useMoveContainersData()

  return (
    <>
      {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}

      <ToolbarMolecule
        title={t('receipt-containers.title')}
        actionIcon={Icons.ArrowBack}
        onAction={actions.goToOrderDetail}
      ></ToolbarMolecule>
      <PageContentAtom>
        <TabGroupMolecule
          tabs={actions.getTabs()}
          onSelectTab={actions.onChangeTab}
        />
        {containers[activeTab]?.map((container, idx) => (
          <ContainerCardMolecule key={idx} container={container} />
        ))}
        <FillerAtom $height={150} />

        <BottomGroupMolecule>
          <ScannerMolecule
            cardMode
            label={t('receipt-containers.scan-label')}
            onScan={actions.onScan}
          />
          <PrimaryButtonAtom
            type="button"
            disabled={!!containers.toMove?.length || !enableFinish}
            onClick={actions.onFinishOrder}
          >
            {t('receipt-containers.finish-order')}
          </PrimaryButtonAtom>
        </BottomGroupMolecule>
      </PageContentAtom>
    </>
  )
}

export default MoveContainersPage
