import { getRejectionSummary } from 'client/receipt/receipt.client'
import type { LoaderFunctionArgs } from 'react-router-dom'
import type { RejectionSummary } from 'types/receipt/model'

export const isRejectionSummary = (data: unknown): data is RejectionSummary => {
  const hasAllProperties =
    !!data &&
    typeof data === 'object' &&
    'totalReceived' in data &&
    'totalRejected' in data &&
    'totalMissing' in data &&
    'totalReceivedBoxes' in data &&
    'totalRejectedBoxes' in data &&
    'totalMissingBoxes' in data
  if (!hasAllProperties) {
    return false
  }

  if ('rejection' in data) {
    return (
      !!data.rejection &&
      typeof data.rejection === 'object' &&
      'photos' in data.rejection &&
      'quantity' in data.rejection &&
      'lot' in data.rejection &&
      'rejectedReasons' in data.rejection
    )
  }
  return true
}

export const rejectionLoader = async ({
  request,
  params
}: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  const queryParams = new URLSearchParams(url.search)
  const rejectionId = queryParams.get('rejectionId') || undefined

  return await getRejectionSummary(
    `${params.receiptId}`,
    `${params.productId}`,
    rejectionId
  )
}
