import { useAtomValue, useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Analytics } from 'analytics/analytics.events'
import { scannedContainer, selectedProductAtom } from '../Receipt.state'
import { useGoToProductLots } from '../product-lots/ProductLots.navigator'
import { useQueryParam, useRequiredParams } from 'hooks/params.hook'
import { isValidLPNScan, isValidLPNWarehouse } from 'utils/helpers'
import { toastError } from 'utils/toast'
import { validateContainer as containerValidation } from 'client/receipt/receipt.client'
import {
  getCurrentCountry,
  getCurrentWarehouse
} from 'client/utilities.client-helper'

export const useScanContainer = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const product = useAtomValue(selectedProductAtom)
  const setScannedContainer = useSetAtom(scannedContainer)
  const { orderId, receiptId, receiptType } = useRequiredParams(
    'orderId',
    'receiptId',
    'receiptType'
  )
  const productLots = useGoToProductLots()
  const from = useQueryParam('from')

  const validateContainer = async (scanned: string) => {
    if (!product) return
    if (!isValidLPNScan(scanned)) {
      toastError(t('receipt-scan-container.invalid-input', { lpn: scanned }))
      return
    }
    if (!isValidLPNWarehouse(scanned)) {
      const current = getCurrentWarehouse()
      toastError(
        t('receipt-scan-container.invalid-warehouse-lpn', { scanned, current })
      )
      return
    }
    Analytics.receipt.scanContainer(
      {
        orderId,
        receiptType,
        originContainerName: scanned
      },
      product
    )

    const validation = await containerValidation(scanned)

    if (validation.containerExists && !validation.locationPreStorage) {
      toastError(t('receipt-scan-container.displaced-container'))
      return
    }

    console.info('container validation', validation)
    const country = getCurrentCountry()
    const validEmptyContainer =
      !validation.containerHasItems || country === 'PE'
    if (!validEmptyContainer) {
      toastError(
        'No se puede usar el contenedor porque ya contiene productos, intente con otro'
      )
      return
    }

    setScannedContainer(scanned)
    const editionMode = from === 'lots'
    productLots.go(receiptId, orderId, product.productId, editionMode)
  }

  const goBack = () => {
    if (!product) return
    if (from === 'lots') {
      productLots.go(receiptId, orderId, product.productId, true)
    } else {
      navigate(-1)
    }
  }

  return {
    product,
    actions: {
      validateContainer,
      goBack
    }
  }
}
